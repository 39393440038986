<template>
  <header :class="{ fixed, visible: fixedVisible }">
    <div :class="{ wrap: fixed }" class="header-inner">
      <div v-if="fixed" v-scroll-to="{ el: 'body', duration: 900, offset: 0 }" class="logo"></div>
      <div v-else class="logo"></div>
      <div class="slogan">{{ $t('slogan') }}</div>
      <div class="lang-select-wrap">
        <div class="label">language:</div>
        <div class="select-wrap">
          <LangSelect />
        </div>
      </div>
      <div class="buttons-wrap">
        <button
          v-scroll-to="{
            el: '.registration',
            duration: 1200,
            offset: -125,
          }"
          type="button"
          class="btn btn-orange"
        >
          {{ $t('startPromotion') }}
        </button>
        <button type="button" class="btn btn-blue" @click="openAuth">{{ $t('login') }}</button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import LangSelect from '~/components/LangSelect.vue';

export default {
  name: 'Header',
  components: { LangSelect },
  props: {
    fixed: { type: Boolean },
  },
  computed: {
    ...mapState(['scrollPos']),
    fixedVisible() {
      return this.fixed ? this.scrollPos > 170 : false;
    },
  },
  methods: {
    ...mapMutations(['openAuth']),
  },
};
</script>

<style lang="less" scoped>
header {
  @margin-top: 43px;
  @height: 40px;

  background: linear-gradient(180deg, #fffced 53%, rgba(255, 252, 237, 0) 70%);

  .header-inner {
    display: flex;
    input,
    button {
      outline: none;
    }

    .logo {
      background-image: url('~/assets/img/logo.svg');
      width: 278px;
      height: 120px;
    }

    .slogan {
      margin-top: @margin-top;
      height: @height;
      overflow: hidden;
      margin-left: 12px;
      width: 135px;
      font-size: 18px;
      line-height: 20px;
    }

    .lang-select-wrap {
      margin: @margin-top 0 0 25px;
      display: flex;
      align-items: center;
      height: 40px;

      .label {
        color: #989288;
        font-size: 12px;
        font-family: 'PT Sans Caption', sans-serif;
        margin-right: 12px;
        cursor: default;
      }
    }

    .buttons-wrap {
      margin-top: @margin-top;
      height: @height;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;

      button {
        &:not(:first-child) {
          margin-left: 24px;
        }
      }
    }
  }

  &.fixed {
    @height: 130px;

    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    transition: top 0.25s ease;
    margin-bottom: -@height;
    min-width: 1170px;

    &:not(.visible) {
      visibility: hidden;
      top: -100px;
    }

    .header-inner {
      padding: 12px 0 0 0;
      height: @height;
      box-sizing: border-box;
    }

    .logo {
      background-image: url('~/assets/img/logo-mini.svg');
      width: 170px;
      height: 43px;
      cursor: pointer;
    }

    .lang-select-wrap {
      margin-left: 90px;
    }

    .slogan,
    .lang-select-wrap,
    .buttons-wrap {
      margin-top: 7px;
    }
  }
}
</style>

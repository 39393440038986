// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/select-arrow.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/flags/ru.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/img/flags/en.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lang-select[data-v-4081a696]{position:relative}.lang-select:hover .list[data-v-4081a696]{display:flex}.lang-select .current[data-v-4081a696]{background:#fffced;border-radius:8px;box-shadow:0 4px 4px #e7ded2,inset 0 2px 0 #fff}.lang-select .current .lang[data-v-4081a696]{padding-right:25px}.lang-select .current .lang[data-v-4081a696]:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");content:\"\";display:block;height:5px;position:absolute;right:7px;top:50%;width:8px}.lang-select .list[data-v-4081a696]{background:linear-gradient(180deg,#fff,#fffef6);border-radius:6px;box-shadow:0 10px 20px #f7ecd8,0 6px 4px hsla(31,28%,63%,.05);display:none;flex-direction:column;left:0;overflow:hidden;position:absolute;top:0}.lang-select .list .lang[data-v-4081a696]{cursor:pointer}.lang-select .list .lang[data-v-4081a696]:hover{background-color:#c6ae97;color:#fff}.lang-select .lang[data-v-4081a696]{background-position:10px;background-repeat:no-repeat;background-size:24px 16px;color:inherit;cursor:pointer;font-size:18px;line-height:20px;padding:5px 23px 5px 40px;-webkit-text-decoration:none;text-decoration:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}.lang-select .lang[lang=ru][data-v-4081a696]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.lang-select .lang[lang=en][data-v-4081a696]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

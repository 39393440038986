<template>
  <table>
    <thead>
      <tr>
        <th class="domain-cell">{{ $t('domain') }}</th>
        <th v-if="!foreign" class="iks-cell">
          <label for="iks-input">{{ $t('iks') }}</label>
        </th>
        <th v-if="!foreign" class="operator-cell"></th>
        <th class="visits-cell">
          <label for="visits-input">{{ $t('visitsPerMonth') }}</label>
        </th>
        <th class="operator-cell"></th>
        <th class="in-the-index-cell">
          <label for="in-the-index-input">{{ $t('inTheIndex') }}</label>
        </th>
        <th class="operator-cell"></th>
        <th class="site-age-cell">
          <label for="site-age-input">{{ $t('siteAge') }}</label>
        </th>
        <th class="operator-cell"></th>
        <th class="moz-rank-cell">
          <label for="moz-rank-input">{{ $t('mozRank') }}</label>
        </th>
        <th class="operator-cell"></th>
        <th class="majestic-seo-cell">
          <label for="majestic-seo-cf-input">{{ $t('majesticSeo') }}</label>
          <div>{{ $t('cfTf') }}</div>
        </th>
        <th class="operator-cell"></th>
        <th class="dmoz-cell">
          <label for="dmoz-input">{{ $t('DMOZ') }}</label>
        </th>
        <th class="operator-cell"></th>
        <th class="acceptors-cell">
          <label for="acceptors-input">{{ $t('acceptors') }}</label>
        </th>
        <th class="operator-cell"></th>
        <th class="donors-cell">
          <label for="donors-input">{{ $t('donors') }}</label>
        </th>
        <th class="operator-cell"></th>
        <th class="spamming-cell">{{ $t('spamming') }}</th>
        <th class="operator-cell"></th>
        <th class="price-cell">{{ $t('price') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="calculator">
        <td class="domain-cell"></td>
        <td v-if="!foreign" class="iks-cell">
          <input
            id="iks-input"
            v-model="calculatorData.iks"
            max="99999"
            min="0"
            type="number"
            autocomplete="off"
            @input="numberInput($event, 'iks')"
          />
        </td>
        <td v-if="!foreign" class="operator-cell">+</td>
        <td class="visits-cell">
          <input
            id="visits-input"
            v-model="calculatorData.visits"
            max="9999999"
            min="0"
            type="number"
            autocomplete="off"
            @input="numberInput($event, 'visits')"
          />
        </td>
        <td class="operator-cell">+</td>
        <td class="in-the-index-cell">
          <input
            id="in-the-index-input"
            v-model="calculatorData.gPages"
            max="9999999"
            min="0"
            type="number"
            autocomplete="off"
            @input="numberInput($event, 'gPages')"
          />
        </td>
        <td class="operator-cell">+</td>
        <td class="site-age-cell">
          <input
            id="site-age-input"
            v-model="calculatorData.siteAge"
            max="99"
            min="0"
            type="number"
            autocomplete="off"
            @input="numberInput($event, 'siteAge')"
          />
        </td>
        <td class="operator-cell">+</td>
        <td class="moz-rank-cell">
          <input
            id="moz-rank-input"
            v-model="calculatorData.mozRank"
            max="10"
            min="0"
            step="0.1"
            type="number"
            autocomplete="off"
            @input="numberInput($event, 'mozRank')"
          />
        </td>
        <td class="operator-cell">+</td>
        <td class="majestic-seo-cell">
          <input
            id="majestic-seo-cf-input"
            v-model="calculatorData.citationFlow"
            max="99"
            min="0"
            type="number"
            autocomplete="off"
            @input="numberInput($event, 'citationFlow')"
          />
          <input
            id="majestic-seo-tf-input"
            v-model="calculatorData.trustFlow"
            max="99"
            min="0"
            type="number"
            autocomplete="off"
            @input="numberInput($event, 'trustFlow')"
          />
        </td>
        <td class="operator-cell">+</td>
        <td class="dmoz-cell">
          <label class="checkbox">
            <input
              id="dmoz-input"
              v-model="calculatorData.dmoz"
              type="checkbox"
              autocomplete="off"
              @input="numberInput($event, 'dmoz')"
            />
            <span class="checkmark"></span>
          </label>
        </td>
        <td class="operator-cell">+</td>
        <td class="acceptors-cell">
          <input
            id="acceptors-input"
            v-model="calculatorData.acceptors"
            max="99999"
            min="0"
            type="number"
            autocomplete="off"
            @input="numberInput($event, 'acceptors')"
          />
        </td>
        <td class="operator-cell">/</td>
        <td class="donors-cell">
          <input
            id="donors-input"
            v-model="calculatorData.donors"
            max="99999"
            min="0"
            type="number"
            autocomplete="off"
            @input="numberInput($event, 'donors')"
          />
        </td>
        <td class="operator-cell">+</td>
        <td class="spamming-cell">{{ calculatorData.spamming }}</td>
        <td class="operator-cell">=</td>
        <td class="price-cell">
          <span class="val">
            {{ lang === 'en' ? '$ ' : '' }}
            <b> {{ result }} </b>
            {{ lang === 'ru' ? ' ₽' : '' }}
          </span>
        </td>
      </tr>
      <tr v-for="item in tableData" :key="item.site_id">
        <td class="domain-cell">{{ item.domain }}</td>
        <td v-if="!foreign" class="iks-cell">{{ item.iks ? formatFooterNumbers(item.iks) : 0 }}</td>
        <td v-if="!foreign"></td>
        <td class="visits-cell">{{ formatFooterNumbers(item.visits) }}</td>
        <td></td>
        <td class="in-the-index-cell">{{ formatFooterNumbers(item.g_pages) }}</td>
        <td></td>
        <td class="site-age-cell">{{ item.domen_cr }}</td>
        <td></td>
        <td class="moz-rank-cell">{{ (item.upa / 10).toFixed(1) }}</td>
        <td></td>
        <td class="majestic-seo-cell">{{ item.citation_flow + ' / ' + item.trust_flow }}</td>
        <td></td>
        <td class="dmoz-cell">{{ item.dmoz }}</td>
        <td></td>
        <td class="acceptors-cell">{{ formatFooterNumbers(item.acceptors) }}</td>
        <td>/</td>
        <td class="donors-cell">{{ formatFooterNumbers(item.donors) }}</td>
        <td></td>
        <td class="spamming-cell">
          {{ toFixed(parseFloat(item.acceptors) / parseFloat(item.donors)) }}
        </td>
        <td></td>
        <td class="price-cell bold">
          {{ lang === 'en' ? '$ ' : '' }}
          <b> {{ formatFooterNumbers(item.price) }} </b>
          {{ lang === 'ru' ? ' ₽' : '' }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/* eslint-disable no-unused-expressions */

import axios from 'axios';

export default {
  name: 'DonorsTable',
  props: {
    foreign: { type: Boolean },
  },
  data() {
    return {
      calculatorData: {
        foreign: 0,
        spamming: 0,
      },
      tableData: [],
      result: 0,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    currencyConf() {
      return this.$i18n.locale === 'en'
        ? {
            symbol: '$',
            thousandsSeparator: '.',
            fractionCount: 0,
            fractionSeparator: ',',
            symbolPosition: 'front',
            symbolSpacing: true,
          }
        : {
            symbol: '₽',
            thousandsSeparator: ' ',
            fractionCount: 0,
            fractionSeparator: ',',
            symbolPosition: 'back',
          };
    },
  },
  mounted() {
    axios({
      method: 'get',
      url: `${this.$config.serverUrl}/${this.lang}/app/modal/account.php?id=11&foreign=${Number(this.foreign)}&url=1`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
      .then(response => {
        this.tableData = response.data;
      })
      .catch(error => {
        // handle error
        console.error(error);
      });
  },
  methods: {
    formatFooterNumbers(value) {
      let str = `${value}`;
      let result = '';
      const count = str.length % 3;
      if (str.length > 3) {
        if (count === 0) {
          for (let i = 0; i < str.length / 3; i++) {
            result += `${str.slice(i * 3, (i + 1) * 3)} `;
          }
        } else {
          const start = str.slice(0, count);
          result += `${start} `;
          str = str.slice(count, str.length);
          for (let i = 0; i < str.length / 3; i++) {
            result += `${str.slice(i * 3, (i + 1) * 3)} `;
          }
        }
      } else {
        result = str;
      }
      return result;
    },
    numberInput({ target: { min, max, value } }, field) {
      if (value[0] === '0') {
        this.calculatorData[field] = Number(value.substr(1));
        this.$forceUpdate();
      }
      value = Number(value); // eslint-disable-line no-param-reassign
      max = Number(max); // eslint-disable-line no-param-reassign
      const data = this.calculatorData;
      const fd = new FormData();
      let spamming = data.donors ? this.toFixed(data.acceptors / data.donors) : 0;
      if (value > max) {
        this.calculatorData[field] = max;
        this.$forceUpdate();
      } else if (value < min) {
        this.calculatorData[field] = min;
        this.$forceUpdate();
      }
      if (Number.isInteger(parseFloat(spamming))) {
        // eslint-disable-next-line radix
        spamming = parseInt(spamming);
      }
      data.foreign = this.foreign;
      data.spamming = spamming;
      !data.iks && (data.iks = 0);
      !data.visits && (data.visits = 0);
      !data.acceptors && (data.acceptors = 0);
      !data.donors && (data.donors = 0);
      !data.siteAge && (data.siteAge = 0);
      !data.citationFlow && (data.citationFlow = 0);
      !data.trustFlow && (data.trustFlow = 0);
      !data.gPages && (data.gPages = 0);
      !data.dmoz ? (data.dmoz = 0) : (data.dmoz = 1);
      !data.mozRank && (data.mozRank = 0);
      fd.set('foreign', data.foreign);
      fd.set('spamming', data.spamming);
      fd.set('iks', data.iks);
      fd.set('visits', data.visits);
      fd.set('acceptors', data.acceptors);
      fd.set('donors', data.donors);
      fd.set('created', data.siteAge);
      fd.set('citationFlow', data.citationFlow);
      fd.set('trustFlow', data.trustFlow);
      fd.set('gPages', data.gPages);
      fd.set('dmoz', data.dmoz);
      fd.set('mozRank', data.mozRank);
      axios({
        method: 'post',
        url: `${this.$config.serverUrl}/${this.lang}/app/modal/account.php?id=10`,
        data: fd,
        headers: { 'Content-Type': 'multipart/form-data', 'X-Requested-With': 'XMLHttpRequest' },
      })
        .then(response => {
          this.result = response.data.finishPrice;
        })
        .catch(error => {
          // handle error
          console.error(error);
        });
    },
    toFixed(number) {
      const result = number.toString().match(/^-?\d+(?:\.\d{0,2})?/);
      if (result !== null) {
        return result[0];
      }
      return 0;
    },
  },
};
</script>

<style lang="less" scoped>
table {
  border-spacing: 0;
  border: none;
  width: 100%;
  color: #282726;
  font-size: 14px;
  text-align: center;

  thead {
    font-size: 14px;
    color: #9d9483;
    user-select: none;
    cursor: default;

    tr {
      vertical-align: top;

      th {
        font-weight: normal;
        padding: 17px 10px 0;

        > * {
          &:nth-child(2) {
            margin-top: 8px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      padding: 0;
      margin: 0;
      border: none;

      &:nth-child(even) {
        background-color: #fbf6ec;
      }

      td {
        padding: 15px 10px;
        margin: 0;
        border: none;

        &.domain-cell {
          color: #b93d01;
          padding-left: 35px;
        }

        &.price-cell {
          padding-right: 20px;
        }
      }

      &.calculator {
        td {
          padding-top: 7px;
          font-size: 18px;

          input {
            &[type='text'],
            &[type='number'] {
              height: 32px;
              width: 64px;
              padding-top: 0;
              padding-bottom: 0;
              text-align: center;
              font-size: 18px;
            }
          }

          &.site-age-cell,
          &.moz-rank-cell {
            input {
              width: 48px;
            }
          }

          &.dmoz-cell {
            text-align: center;

            .checkbox {
              margin-top: 3px;
            }
          }

          &.majestic-seo-cell {
            display: flex;
            justify-content: center;

            input {
              width: 32px;
            }
          }
        }
      }
    }
  }

  th,
  td {
    &.iks-cell,
    &.visits-cell,
    &.in-the-index-cell {
      text-align: right;
    }

    &.domain-cell {
      text-align: left;
      padding-left: 35px;
    }

    &.acceptors-cell {
      text-align: right;
      padding-right: 0;
    }

    &.operator-cell {
      padding-left: 0;
      padding-right: 0;
      width: 16px;
      user-select: none;
      cursor: default;
    }

    &.donors-cell {
      padding-left: 0;
      text-align: left;
    }

    &.price-cell {
      text-align: right;
      padding-right: 35px;
    }
  }
}
</style>

<template>
  <div class="our-donors-table">
    <div class="section-image"></div>
    <h2>{{ $t('ourDonorSites') }}:</h2>

    <div class="features">
      <div>
        <span v-html="$t('doNotSellLinks')"></span>
      </div>
      <div>
        <span v-html="$t('placementCostDependsOnSite')"></span>
      </div>
    </div>
    <div class="expand-wrap">
      <div class="tabs" :class="{ reverse: lang === 'en' }">
        <div
          class="tab"
          :class="{ active: tabChanged ? tabChanged === 1 : tab === 1 }"
          @click="tabChanged = 1"
        >
          {{ $t('cis') }} ({{ donorsSitesData.cis }})
        </div>
        <div
          id="tab-foreign"
          class="tab"
          :class="{ active: tabChanged ? tabChanged === 2 : tab === 2 }"
          @click="tabChanged = 2"
        >
          {{ $t('foreign') }} ({{ donorsSitesData.international }})
        </div>
      </div>
      <div class="table-wrap">
        <DonorsTable v-if="tabChanged ? tabChanged === 1 : tab === 1" key="cis" />
        <DonorsTable v-else key="foreign" foreign />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import DonorsTable from '~/components/DonorsTable.vue';

export default {
  name: 'OurDonorsSites',
  components: { DonorsTable },
  data() {
    return {
      tabChanged: 0,
      donorsSitesData: [],
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    tab() {
      return this.lang === 'en' ? 2 : 1;
    },
  },
  mounted() {
    axios({
      method: 'get',
      url: `${this.$config.serverUrl}/${this.lang}/app/modal/account.php?id=13`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
      .then(response => {
        this.donorsSitesData = response.data;
      })
      .catch(error => {
        console.error(error)
      });
  },
};
</script>

<style lang="less" scoped>
.our-donors-table {
  @border-radius: 20px;
  @box-shadow: 0 10px 20px #f7ecd8, 0 6px 4px rgba(186, 160, 133, 0.05);

  .section-image {
    background-image: url('~/assets/img/our-donors.svg');
  }

  .features {
    display: flex;
    width: 1050px;
    margin: 0 auto 40px;
    font-size: 24px;

    > * {
      padding-left: 75px;
      background-position: left center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      height: 50px;

      &:nth-child(1) {
        flex-grow: 1;
        background-image: url('~/assets/img/features-1.svg');
      }

      &:nth-child(2) {
        width: 520px;
        background-image: url('~/assets/img/features-2.svg');
        margin-left: 40px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 42px;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url('~/assets/img/donors-table-pr.png');
          width: 99px;
          height: 103px;
        }
      }
    }
  }

  .expand-wrap {
    margin: 0 -13px;
  }

  .tabs {
    display: flex;

    &.reverse {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    .tab {
      box-shadow: @box-shadow;
      text-align: center;
      border-radius: @border-radius @border-radius 0 0;
      overflow: hidden;
      height: 56px;
      line-height: 56px;
      padding: 0 40px;
      user-select: none;
      font-size: 24px;

      &.active {
        cursor: default;
        color: #4e4141;
        background-color: #fffef6;
      }

      &:not(.active) {
        color: #ffffff;
        cursor: pointer;
        background: linear-gradient(360deg, #b89d86 0%, #d8c5ae 100%);

        &:hover {
          background: linear-gradient(360deg, #b89d86 0%, #e6d1ba 100%);
        }
      }
    }
  }

  .table-wrap {
    position: relative;
    z-index: 3;
    background-color: #fffef6;
    border-radius: 0 @border-radius @border-radius @border-radius;
    box-shadow: @box-shadow;
  }
}
</style>

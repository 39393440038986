// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/footer.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/footer-gradient.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/img/calendar.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/img/link.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/img/site.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/img/client.svg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../assets/img/footer-logo.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "footer[data-v-14bd11a6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%,0 100%;background-repeat:no-repeat,repeat-x;color:#5b4941;display:flex;flex-direction:column;height:633px;justify-content:flex-end;margin-top:30px;min-width:1170px}footer ul[data-v-14bd11a6]{align-items:center;border:1px solid #fff;border-radius:40px;display:flex;height:70px;justify-content:space-around;list-style:none;margin:0 auto 40px;padding:0;width:910px}footer ul li[data-v-14bd11a6]{background-position:0;background-repeat:no-repeat;margin:0;padding:10px 0 10px 40px}footer ul li[data-v-14bd11a6]:first-child{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}footer ul li[data-v-14bd11a6]:nth-child(2){background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}footer ul li[data-v-14bd11a6]:nth-child(3){background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}footer ul li[data-v-14bd11a6]:nth-child(4){background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}footer .logo-and-slogan[data-v-14bd11a6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");background-position:top;background-repeat:no-repeat;font-size:14px;line-height:20px;margin-bottom:40px;padding-top:35px;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

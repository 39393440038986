<template>
  <div>
    <Header fixed/>
    <div class="wrap">
      <Header/>
      <section class="first">
        <FirstScreen/>
        <LinksOrArticles/>
      </section>
      <section>
        <WeGuarantee/>
      </section>
      <section>
        <OurDonorsSites/>
      </section>
      <section v-if="$i18n.locale === 'ru'">
        <Comparison/>
      </section>
      <section class="last">
        <Registration/>
      </section>
      <section class="last" v-if="$i18n.locale === 'ru'">
        <h1 class="page_name" style="margin-bottom: 30px;text-align: center">ВЕЧНЫЕ ССЫЛКИ И СТАТЬИ</h1>
        <div class="clear"></div>

        <div class="static_page">

          <b>Вечные ссылки - это основной инструмент в продвижении сайтов.</b>
          <br/><br/>
          Поисковые системы Яндекс и Google сканируют все сайты, анализируя их на множество факторов, один из них, и, пожалуй, ключевой на сегодняшний день, - это ссылки. Позиции вашего сайта в поисковиках зависят напрямую от того, как много и на сколько трастовых (авторитетных) сайтов ссылается на ваш сайт. Ссылки бывают двух типов: вечные и временные (с оплатой помесячно).
          <br/><br/><br/>
          <br/><b>Для продвижения сайта лучше использовать вечные ссылки, а не временные.</b>
          <br/><br/>Сегодня Вы готовы оплатить аренду ссылок на месяц, и может быть через месяц это тоже будет не проблема, но когда Вы не оплатите их (может просто забудете это сделать) - они все одновременно пропадут, а восстановить их в прежнем виде будет не возможно - это очень негативно отразится на результатах в продвижении вашего сайта. И получится, что все потраченные деньги были выброшены на ветер.
          <br/><br/>Временные ссылки крайне редко предлагают на качественных сайтах, а еще они размещаются не в тесте статей и новостей, а где-нибудь в футере (внизу сайта) или еще каком-нибудь блоке, в котором нет ни чего, кроме ссылок. Как правило, не возможно даже задать околоссылочный текст для того, чтобы они выглядели более естественно. Поисковику в таком случае совсем не сложно понять их происхождение (продажность) и он не будет особо обращать на них внимание, соответственно толку в продвижении вашего сайта от таких ссылок практически не будет.
          <br/><br/>Покупая вечные ссылки, вам не нужно будет постоянно беспокоиться об оплате, потому что вечные ссылки размещаются один раз и навсегда и подразумевают разовую оплату при размещении. Используя именно вечные ссылки в продвижении сайта, Вы освобождаетесь от рисков пропадания ссылок и, как следствие, падения позиций в поисковике.
          <br/><br/>Вечные ссылки выглядят максимально естественно для поисковиков, они окружены тематическим околоссылочным текстом и размещаются в тексте, а не отдельным "палевным" блоком.
          <br/><br/>
          <br/><b>На каких площадках лучше размещать вечные ссылки для максимального эффекта в продвижении сайта?</b>
          <br/><br/>Обязательно, чтобы площадка была не заспамленна ссылками, т.е. на неё ссылается сайтов больше, чем она на другие (доноров больше, чем акцепторов). Такого качество площадки очень сложно найти в биржах ссылок, их там просто единицы. Но даже если Вы потратите много времени и подберете такие площадки, со временем окажется, что Вы просто были одним из первых, кто купил там ссылки, пока она была еще не заспамленной. Площадка станет со временем заспамленной только потому, что участвует в бирже ссылок.
          <br/><br/>
          <br/><b>Что же предлагаем мы?</b>
          <br/><br/>Мы предлагаем самостоятельное продвижение сайта с помощью вечных ссылок на не заспамленных площадках отборного качества, с которых продается ограниченное колличество ссылок.


        </div>
      </section>
      <section class="last" v-else>
        <h1 class="page_name" style="margin-bottom: 30px;text-align: center">PERMANENT LINKS AND ARTICLES</h1>
        <div class="clear"></div>

        <div class="static_page">

          <b>Permanent links are the main tool in website promotion.</b>
          <br/><br/>
          Search engines like Yandex and Google scan all websites, analyzing them for numerous factors, one of the key ones today being links. Your website's positions in search engines directly depend on how many and how authoritative (trustworthy) websites link to your site. Links can be of two types: permanent and temporary (with monthly payment).
          <br/><br/><br/>
          <br/><b>For promoting a website, it's better to use permanent links rather than temporary ones.</b>
          <br/><br/>Today, you might be ready to pay for renting links for a month, and maybe it won't be a problem next month either, but if you fail to pay for them (maybe you just forget to do it) - they will all disappear at once, and restoring them to their previous state will be impossible - this will negatively impact the results of promoting your site. It turns out that all the money spent was wasted.
          <br/><br/>Temporary links are very rarely offered on quality sites, and they are placed not in the text of articles and news but somewhere in the footer (at the bottom of the site) or some other block that contains nothing but links. Usually, it's not even possible to set surrounding text to make them look more natural. For a search engine, it's not hard to understand their origin (being paid for) and it won't pay much attention to them, consequently, there will hardly be any benefit in promoting your site from such links.
          <br/><br/>By purchasing permanent links, you won't have to constantly worry about payment, because permanent links are placed once and for all and imply a one-time payment upon placement. By using precisely permanent links in website promotion, you free yourself from the risks of links disappearing and, as a consequence, dropping positions in search engines.
          <br/><br/>Permanent links look most natural to search engines, they are surrounded by thematic surrounding text and are placed in the text, not in a separate "obvious" block.
          <br/><br/>
          <br/><b>What platforms are best for placing permanent links for maximum effect in website promotion?</b>
          <br/><br/>It's essential that the platform is not spammed with links, i.e., more sites link to it than it does to others (more donors than acceptors). Finding such quality platforms on link exchanges is very difficult; they are simply few and far between. But even if you spend a lot of time and select such platforms, over time, it turns out that you were just one of the first to buy links there while it was not yet spammed. The platform will become spammed over time simply because it participates in a link exchange.
          <br/><br/>
          <br/><b>What do we offer then?</b>
          <br/><br/>We offer independent website promotion using permanent links on unspammed platforms of select quality, from which only a limited number of links are sold.


        </div>
      </section>

    </div>

    <Footer/>
    <AuthDialog/>
    <Parallax/>
  </div>
</template>

<script>
import '~/assets/js/yandex';
import {mapMutations} from 'vuex';
import {extend, localize} from 'vee-validate';
import {required, email} from 'vee-validate/dist/rules';

import dotenv from 'dotenv';
import ru from '~/lang/ru';
import en from '~/lang/en';

import {getCurrentScrollPos} from '~/assets/js/helpers';
import Header from '~/components/Header.vue';
import Footer from '~/components/Footer.vue';
import AuthDialog from '~/components/AuthDialog.vue';
import Registration from '~/components/Registration.vue';
import FirstScreen from '~/components/FirstScreen.vue';
import WeGuarantee from '~/components/WeGuarantee.vue';
import Comparison from '~/components/Comparison.vue';
import OurDonorsSites from '~/components/OurDonorsSites.vue';
import LinksOrArticles from '~/components/LinksOrArticles.vue';
import Parallax from '~/components/Parallax.vue';

dotenv.config();

localize({
  en: {messages: en.validate},
  ru: {messages: ru.validate},
});

extend('required', required);
extend('email', email);

export default {
  components: {
    Parallax,
    LinksOrArticles,
    OurDonorsSites,
    Comparison,
    WeGuarantee,
    FirstScreen,
    Registration,
    Footer,
    AuthDialog,
    Header,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.$nextTick(() => {
      this.loading = false;
    });
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onScroll);
    window.addEventListener('keydown', this.onKeyDown);

    localize(this.$i18n.locale);
    this.onScroll();
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    ...mapMutations(['setScrollPos', 'closeAuth']),
    onScroll() {
      const scrollPos = getCurrentScrollPos();
      this.setScrollPos(scrollPos < 0 ? 0 : scrollPos);
    },
    onKeyDown({keyCode}) {
      if (keyCode === 27) this.closeAuth();
    },
  },
  head() {
    let jivo = 'ZNopNqmdlu';
    if (this.$i18n.locale === 'en') {
      jivo = 'MTwiUlYxjT';
    }

    return {
      title: this.$t('title'),
      meta: [
        {
          name: 'description',
          content: this.$t('meta.description'),
        },
        {
          name: 'keywords',
          content: this.$t('meta.keywords'),
        },
      ],
      script: [{src: `https://code.jivosite.com/widget/${jivo}`}],
      link: [
        {rel: "canonical", href: `https://trastik.com/${this.$i18n.locale === 'en' ? 'en' : ''}`},
        {rel: "alternate", href: "https://trastik.com/", hreflang: 'ru-RU'},
        {rel: "alternate", href: "https://trastik.com/en", hreflang: 'en-US'},
        {
          rel: 'preload',
          href: require('~/assets/fonts/ptsanscaption_regular_macroman/PTC55F-webfont.woff'),
          as: 'font',
          type: 'font/woff2',
          crossorigin: 'anonymous'
        },
        {
          rel: 'preload',
          href: require('~/assets/fonts/ptsanscaption_bold_macroman/PTC75F-webfont.woff'),
          as: 'font',
          type: 'font/woff2',
          crossorigin: 'anonymous'
        },
        {
          rel: 'preload',
          href: require('~/assets/fonts/ptsansnarrow_regular_macroman/PTN57F-webfont.woff'),
          as: 'font',
          type: 'font/woff2',
          crossorigin: 'anonymous'
        },
        {
          rel: 'preload',
          href: require('~/assets/fonts/ptsansnarrow_bold_macroman/PTN77F-webfont.woff'),
          as: 'font',
          type: 'font/woff2',
          crossorigin: ''
        },
        {
          rel: 'preload',
          href: require('~/assets/img/animations/2.png'),
          as: 'image',
          type: 'image/png',
          crossorigin: 'anonymous'
        },
        {
          rel: 'preload',
          href: require('~/assets/img/first-ya.png'),
          as: 'image',
          type: 'image/png',
          crossorigin: 'anonymous'
        },
        {
          rel: 'preload',
          href: require('~/assets/img/logo.svg'),
          as: 'image',
          type: 'image/svg+xml',
          crossorigin: 'anonymous'
        },
        {
          rel: 'preload',
          href: require('~/assets/img/flags/ru.svg'),
          as: 'image',
          type: 'image/svg+xml',
          crossorigin: 'anonymous'
        },
        {
          rel: 'preload',
          href: require('~/assets/img/flags/en.svg'),
          as: 'image',
          type: 'image/svg+xml',
          crossorigin: 'anonymous'
        }
      ]
    };
  },
};
</script>

<style lang="less" scoped>
section {
  margin: 80px 0;

  &.first {
    margin: 40px 0 80px;
  }

  &.last {
    margin-bottom: 0;
  }
}
</style>

<template>
  <div class="first-screen">
    <div class="first-screen-header" :class="{ en: $i18n.locale === 'en' }">
      <div class="promote-sites">
        <h1>{{ $t('promote') }}</h1>
        <h1>{{ $t('sites') }}</h1>
      </div>
      <div class="line"></div>
      <div class="any-subjects">
        <h3>{{ $t('any') }}</h3>
        <h3>{{ $t('subjects') }}</h3>
      </div>
    </div>
    <div class="first-screen-main">
      <div
        class="animation"
        :style="{ backgroundPositionY }"
        :class="{ first: backgroundPositionY === '0px' }"
      >
        <div v-if="$i18n.locale === 'ru'" class="ya" :style="{ bottom: `${transitionImg}%` }"></div>
        <div
          v-if="$i18n.locale === 'ru'"
          class="transition"
          :class="{ active: transitionImg && transitionImg < 100 }"
          :style="{ top: `${100 - transitionImg}%` }"
        ></div>
      </div>
      <div class="texts">
        <div class="text">
          <div>{{ $t('topTexts.1') }}</div>
          <div class="big">
            <span class="links">{{ $t('links') }}</span>
            <span>{{ $t('and') }}</span>
            <span class="articles">{{ $t('articles') }}</span>
          </div>
        </div>
        <div class="text vertical-center" v-html="$t('topTexts.2')"></div>
        <div class="text vertical-center" v-html="$t('topTexts.3')"></div>
      </div>
    </div>
    <div class="cache-animation"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const animationStartPos = 120;
const animationFramesCount = 50;
const animationFrameHeight = 372;

export default {
  name: 'FirstScreen',
  computed: {
    ...mapState(['scrollPos']),
    backgroundPositionY() {
      if (this.scrollPos < animationStartPos) return '0px';

      let frame = Math.round((this.scrollPos - animationStartPos) / 8);
      if (frame > animationFramesCount) frame = animationFramesCount;

      return `-${frame * animationFrameHeight}px`;
    },
    transitionImg() {
      const p = 100 / (animationStartPos / this.scrollPos);

      return p > 100 ? 100 : p;
    },
  },
};
</script>

<style lang="less" scoped>
.first-screen {
  position: relative;
  margin: 0 0 80px 0;

  .first-screen-header {
    height: 130px;

    .line {
      position: absolute;
      right: 392px;
      top: 50px;
      transform: rotate(56deg);
      height: 1px;
      width: 200px;
      background-color: #e1d3c2;
    }

    .promote-sites,
    .any-subjects {
      position: absolute;
    }

    .promote-sites {
      text-align: right;
      right: 459px;
      width: 427px;
      height: 116px;
      clip-path: polygon(83.51% -4px, 99.53% 83.21%, 100% 100%, 0 100%, 0 0);

      > * {
        &:nth-child(1) {
          margin-right: 50px;
        }
      }
    }

    .any-subjects {
      text-align: left;
      left: 641px;
      width: 270px;
      height: 76px;
      top: 16px;
      clip-path: polygon(100% 0, 100% 100%, 19% 127.63%, 0 32.74%, 0 0);

      > * {
        &:nth-child(2) {
          margin-left: 21px;
        }
      }
    }

    &.en {
      @offset: 100px;

      .promote-sites {
        right: 459px + @offset;
      }

      .line {
        right: 392px + @offset;
      }

      .any-subjects {
        left: 641px - @offset;
      }
    }
  }

  .first-screen-main {
    .animation {
      margin: 20px 35px 35px auto;
      width: 998px;
      height: 372px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-image: url('~/assets/img/animations/1.png');

      &.first {
        background-image: url('~/assets/img/first.png');
        position: relative;

        .transition {
          width: 1379px;
          height: 59px;
          background-image: url('~/assets/img/transition.svg');
          background-position: top center;
          background-size: 100% auto;
          background-repeat: no-repeat;
          position: absolute;
          left: -190px;
          right: -190px;
          top: 100%;
          opacity: 0;
          transition: opacity 0.2s linear;
          z-index: 2;

          @media (max-width: 1450px) {
            width: 1100px;
            left: -50px;
            right: -50px;
          }

          &.active {
            opacity: 1;
          }
        }

        .ya {
          background-image: url('~/assets/img/first-ya.png');
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: #fffced;
        }
      }
    }

    .texts {
      display: flex;
      align-items: stretch;
      text-align: center;
      font-size: 24px;
      line-height: 32px;

      .text {
        width: 33.33333%;
        position: relative;

        &.vertical-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:not(:last-child) {
          &:after {
            position: absolute;
            top: 43px;
            right: -70px;
            content: '';
            height: 1px;
            width: 110px;
            transform: rotate(56deg);
            background-color: #e1d3c2;
          }
        }

        &:nth-child(1) {
          &:after {
            right: -90px;
          }
        }

        .big {
          font-size: 32px;
          line-height: 40px;
          font-weight: bold;
          font-family: 'PT Sans Caption', sans-serif;
          text-transform: uppercase;

          .links {
            color: #3a65b7;
          }

          .articles {
            color: #e56d0e;
          }
        }
      }
    }
  }

  .cache-animation {
    width: 1px;
    height: 1px;
    position: absolute;
    background-image: url('~/assets/img/animations/1.png');
  }
}
</style>

<template>
  <div ref="rootEl" class="links-or-articles" :class="{ 'table-center': isMobile }">
    <div
      ref="animation"
      class="animation"
      :style="{ backgroundPosition: `left -${animationFrameOffset}px` }"
    ></div>
    <div ref="table" class="table">
      <div
        class="highlight"
        :class="{ visible: highlightedSection }"
        :style="{ top: highlightTop }"
      ></div>
      <div class="header" :class="{ hide: hideTableHeader }">
        <div class="col links">{{ $t('links') }}</div>
        <div class="col articles">{{ $t('articles') }}</div>
      </div>
      <div class="section" :class="{ highlighted: highlightedSection === 1 }">
        <div class="header">
          <span class="text-wrap">{{ $t('placementPeriod') }}</span>
        </div>
        <div class="content">
          <div class="col d-flex justify-content-center">
            <div class="text-wrap icon-infinity width-init">{{ $t('whileSiteWorking') }}</div>
          </div>
        </div>
      </div>
      <div class="section" :class="{ highlighted: highlightedSection === 2 }">
        <div class="header">
          <span class="text-wrap">{{ $t('placementPages') }}</span>
        </div>
        <div class="content">
          <div class="col">
            <div class="text-wrap icon-window">{{ $t('alreadyCreated') }}</div>
          </div>
          <div class="col">
            <div class="text-wrap icon-window-plus">{{ $t('new') }}</div>
          </div>
        </div>
      </div>
      <div class="section" :class="{ highlighted: highlightedSection === 3 }">
        <div class="header">
          <span class="text-wrap">{{ $t('pageIndexing') }}</span>
        </div>
        <div class="content">
          <div class="col">
            <div class="text-wrap icon-check">{{ $t('alreadyInIndex') }}</div>
          </div>
          <div class="col">
            <div class="text-wrap icon-check-70" v-html="$t('warrantyUpTo70days')"></div>
          </div>
        </div>
      </div>
      <div class="section" :class="{ highlighted: highlightedSection === 4 }">
        <div class="header">
          <span class="text-wrap">{{ $t('surroundingText') }}</span>
        </div>
        <div class="content">
          <div class="col">
            <div class="text-wrap icon-text" v-html="$t('xSymbolsLinks', { count: '600' })"></div>
          </div>
          <div class="col">
            <div
              class="text-wrap icon-long-text"
              v-html="$t('xSymbols', { count: '10 000' })"
            ></div>
          </div>
        </div>
      </div>
      <div class="section" :class="{ highlighted: highlightedSection === 5 }">
        <div class="header">
          <span class="text-wrap">{{ $t('deleteAndEdit') }}</span>
        </div>
        <div class="content">
          <div class="col">
            <div class="text-wrap icon-rouble">
              <div>
                <div>{{ $t('deleteAndEditPriceForLinks') }}</div>
                <div>{{ $t('notAlwaysAvailable') }}</div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="text-wrap icon-infinity-2">
              <div>
                <div>{{ $t('free') }}</div>
                <div>{{ $t('entirePlacementPeriod') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section" :class="{ highlighted: highlightedSection === 6 }">
        <div class="header">
          <span class="text-wrap">{{ $t('linkSettings') }}</span>
        </div>
        <div class="content">
          <div class="col d-flex">
            <div class="text-wrap width-init icon-cogs">title="" target=""</div>
          </div>
        </div>
      </div>
      <div class="section" :class="{ highlighted: highlightedSection === 7 }">
        <div class="header">
          <span class="text-wrap">{{ $t('pageMetaTags') }}</span>
        </div>
        <div class="content">
          <div class="col">
            <div class="text-wrap icon-window-empty">{{ $t('not') }}</div>
          </div>
          <div class="col">
            <div class="text-wrap icon-window-tags">
              <small>title<br />description<br />keywords</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MobileDetect from 'mobile-detect';

import { getCurrentScrollPos } from '~/assets/js/helpers';

// Задержка смены кадров анимации (чем меньше значение, тем быстрее)
const animationFrameDelay = 50;

const animationFrames = {
  '1': [0, 9],
  '2': [10, 19],
  '3': [20, 29],
  '4': [30, 39],
  '5': [40, 49],
  '6': [50, 59],
  '7': [60, 69],
};

export default {
  name: 'LinksOrArticles',
  data() {
    return {
      headerHeight: 0,
      sectionHeight: 0,
      sectionsCount: 0,
      currentAnimationFrame: 0,
      animationInterval: null,
      animationFrameHeight: 424,
      tableTopPos: 0,
    };
  },
  computed: {
    ...mapState(['scrollPos']),
    startAnimationPos() {
      return process.client && this.tableTopPos ? this.tableTopPos - window.innerHeight / 2 : 0;
    },
    highlightedSection() {
      if (this.scrollPos < this.startAnimationPos || !this.sectionHeight || this.isMobile) return 0;

      const highlightedSection = Math.ceil(
        (this.scrollPos - this.startAnimationPos - this.sectionHeight / 2) / this.sectionHeight,
      );

      return highlightedSection > this.sectionsCount ? this.sectionsCount : highlightedSection;
    },
    highlightTop() {
      const k = this.highlightedSection > 1 ? this.highlightedSection - 1 : 0;
      return `${this.sectionHeight * k + this.headerHeight}px`;
    },
    animationFrameOffset() {
      if (this.isMobile) return 0;

      return this.currentAnimationFrame * this.animationFrameHeight;
    },
    hideTableHeader() {
      return this.scrollPos > 1500;
    },
    isMobile() {
      if (!process.client) return false;

      const mb = new MobileDetect(window.navigator.userAgent);
      return mb.mobile() || mb.phone() || mb.tablet();
    },
  },
  watch: {
    highlightedSection(highlightedSection, prevHighlightedSection) {
      const reverse = prevHighlightedSection > highlightedSection;

      if (this.animationInterval) clearInterval(this.animationInterval);

      if (highlightedSection || prevHighlightedSection) {
        this.currentAnimationFrame = reverse
          ? animationFrames[prevHighlightedSection][1]
          : animationFrames[highlightedSection][0];

        this.animationInterval = setInterval(() => {
          if (!reverse && animationFrames[highlightedSection][1] > this.currentAnimationFrame) {
            ++this.currentAnimationFrame;
          } else if (
            reverse &&
            animationFrames[prevHighlightedSection][0] <= this.currentAnimationFrame
          ) {
            if (this.currentAnimationFrame > 0) --this.currentAnimationFrame;
          } else {
            clearInterval(this.animationInterval);
          }
        }, animationFrameDelay);
      } else {
        this.currentAnimationFrame = 0;
      }
    },
  },
  mounted() {
    this.sectionsCount = this.$refs.table.querySelectorAll('.section').length;

    setTimeout(() => {
      this.headerHeight = this.$refs.table.querySelector('.header').offsetHeight;
      this.sectionHeight = this.$refs.table.querySelector('.section').offsetHeight;

      this.tableTopPos =
        this.$refs.table.getBoundingClientRect().top + this.headerHeight + getCurrentScrollPos();
    }, 100);
  },
};
</script>

<style lang="less" scoped>
.width-init {
  width: initial !important;
}
.d-flex {
  display: flex;
  justify-content: center;
}
.links-or-articles {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 200px;

  &.table-center {
    display: block;
    margin-bottom: 0;

    .table {
      margin: 275px auto 0;

      > .header {
        position: static;
      }
    }

    .animation {
      position: relative;
      top: 0;
    }
  }

  .animation {
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url('~/assets/img/animations/2.png');
    width: 492px;
    height: 424px;
    position: sticky;
    top: 303px;
    margin: -588px 24px -125px -66px;
  }

  .table {
    width: 690px;
    position: relative;

    .highlight {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: #f8a12d;
      height: 128px;
      box-shadow: 0 10px 20px #f7ecd8, 0 6px 4px rgba(186, 160, 133, 0.05);
      border-radius: 8px;
      opacity: 0;
      transition: opacity 0.125s linear, top 0.3s ease;

      &.visible {
        opacity: 1;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 30px;
        background-color: #e67010;
        border-radius: 8px 8px 0 0;
      }
    }

    > .header {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      font-family: 'PT Sans Caption', sans-serif;
      display: flex;
      position: sticky;
      top: 70px;
      z-index: 49;
      background: linear-gradient(180deg, #fefbed 38.89%, rgba(255, 252, 237, 0) 100%);
      transition: all 0.12s linear;

      &.hide {
        opacity: 0;
      }

      .col {
        width: 50%;
        text-align: center;
        padding: 10px 0;

        &.links {
          color: #3a65b7;
        }

        &.articles {
          color: #e56d0e;
        }

        &:nth-child(2) {
          border-left: 1px solid #e6e0d294;
        }
      }
    }

    .section {
      font-size: 18px;
      line-height: 24px;

      .header,
      .content {
        transition: color 0.3s ease;
      }

      .header {
        text-align: center;
        color: #6a604d;
        background-color: #e6e0d294;
        line-height: 30px;
        height: 30px;

        .text-wrap {
          position: relative;
          z-index: 10;
        }
      }

      .content {
        display: flex;
        align-items: center;

        .col {
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: 0;
          padding: 24px 10px;

          &:nth-child(2) {
            border-left: 1px solid #e6e0d294;
          }

          small {
            font-size: 12px;
            line-height: 16px;
            display: inline-block;
            font-family: 'PT Sans Caption', sans-serif;
          }

          .text-wrap {
            background-position: left center;
            background-repeat: no-repeat;
            width: 180px;
            margin: 0 auto;
            padding-left: 70px;
            min-height: 50px;
            display: flex;
            align-items: center;
            position: relative;
            z-index: 10;

            &:before {
              content: '';
              position: absolute;
              left: -10px;
              top: -6px;
              width: 64px;
              height: 64px;
              background-position: center center;
              background-repeat: no-repeat;
              border-radius: 50%;
              transition: background-color 0.2s linear;
            }

            &.icon-infinity {
              width: 200px;

              &:before {
                background-image: url('~/assets/img/icon-1.svg');
              }
            }

            &.icon-window {
              &:before {
                background-image: url('~/assets/img/icon-2.svg');
              }
            }

            &.icon-window-plus {
              &:before {
                background-image: url('~/assets/img/icon-3.svg');
              }
            }

            &.icon-check {
              &:before {
                background-image: url('~/assets/img/icon-4.svg');
              }
            }

            &.icon-check-70 {
              &:before {
                background-image: url('~/assets/img/icon-5.svg');
              }
            }

            &.icon-text {
              &:before {
                background-image: url('~/assets/img/icon-6.svg');
              }
            }

            &.icon-long-text {
              &:before {
                background-image: url('~/assets/img/icon-7.svg');
              }
            }

            &.icon-rouble {
              &:before {
                background-image: url('~/assets/img/icon-8.svg');
              }
            }

            &.icon-infinity-2 {
              &:before {
                background-image: url('~/assets/img/icon-9.svg');
              }
            }

            &.icon-cogs {
              &:before {
                background-image: url('~/assets/img/icon-10.svg');
              }
            }

            &.icon-window-empty {
              &:before {
                background-image: url('~/assets/img/icon-11.svg');
              }
            }

            &.icon-window-tags {
              &:before {
                background-image: url('~/assets/img/icon-12.svg');
              }
            }
          }
        }
      }

      &.highlighted {
        .header,
        .content {
          color: #ffffff;
          font-weight: bold;
        }

        .content {
          .col {
            .text-wrap {
              &:before {
                background-color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/logo.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/logo-mini.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "header[data-v-762dfb78]{background:linear-gradient(180deg,#fffced 53%,rgba(255,252,237,0) 70%)}header .header-inner[data-v-762dfb78]{display:flex}header .header-inner button[data-v-762dfb78],header .header-inner input[data-v-762dfb78]{outline:none}header .header-inner .logo[data-v-762dfb78]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:120px;width:278px}header .header-inner .slogan[data-v-762dfb78]{font-size:18px;height:40px;line-height:20px;margin-left:12px;margin-top:43px;overflow:hidden;width:135px}header .header-inner .lang-select-wrap[data-v-762dfb78]{align-items:center;display:flex;height:40px;margin:43px 0 0 25px}header .header-inner .lang-select-wrap .label[data-v-762dfb78]{color:#989288;cursor:default;font-family:\"PT Sans Caption\",sans-serif;font-size:12px;margin-right:12px}header .header-inner .buttons-wrap[data-v-762dfb78]{align-items:center;display:flex;flex-grow:1;height:40px;justify-content:flex-end;margin-top:43px}header .header-inner .buttons-wrap button[data-v-762dfb78]:not(:first-child){margin-left:24px}header.fixed[data-v-762dfb78]{left:0;margin-bottom:-130px;min-width:1170px;position:sticky;right:0;top:0;transition:top .25s ease;z-index:50}header.fixed[data-v-762dfb78]:not(.visible){top:-100px;visibility:hidden}header.fixed .header-inner[data-v-762dfb78]{box-sizing:border-box;height:130px;padding:12px 0 0}header.fixed .logo[data-v-762dfb78]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");cursor:pointer;height:43px;width:170px}header.fixed .lang-select-wrap[data-v-762dfb78]{margin-left:90px}header.fixed .buttons-wrap[data-v-762dfb78],header.fixed .lang-select-wrap[data-v-762dfb78],header.fixed .slogan[data-v-762dfb78]{margin-top:7px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

export default {
  title: 'Вечные ссылки и статьи за полцены.',
  meta: {
    description:
      'Продвигайте сайты любой тематики! Размещаем ссылки и статьи, Yandex и Google их учитывают, Ваш сайт поднимается в поиске. Более 1500 СНГ и зарубежных площадок.',
    keywords:
      'Вечные ссылки, статьи, купить ссылки, купить вечные ссылки, самостоятельное продвижение сайтов',
  },
  slogan: 'Вечные ссылки и статьи за полцены',
  languages: {
    ru: 'рус',
    en: 'eng',
  },
  startPromotion: 'Начать продвижение',
  login: 'Войти',
  loginInCabinet: 'Вход в кабинет',
  email: 'E-mail',
  password: 'Пароль',
  name: 'Имя',
  forgotPassword: 'Забыли пароль?',
  promote: 'Продвигайте',
  sites: 'сайты',
  any: 'любой',
  subjects: 'тематики!',
  deleteAndEditPriceForLinks: '100 ₽',
  topTexts: {
    1: 'Размещаем',
    2: 'Yandex и Google<br>их учитывают',
    3: 'Ваш сайт поднимается<br>в поиске',
  },
  links: 'Ссылки',
  and: 'И',
  articles: 'Статьи',
  placementPeriod: 'Срок размещения',
  whileSiteWorking: 'Навечно (пока работает сайт)',
  placementPages: 'Страницы размещения',
  alreadyCreated: 'Уже созданные',
  new: 'Новые',
  pageIndexing: 'Индексация страниц',
  alreadyInIndex: 'Уже в индексе',
  warrantyUpTo70days: 'Обычно до 70 дней',
  surroundingText: 'Околоссылочный текст',
  xSymbols: '{count} символов<br>+ картинки',
  xSymbolsLinks: '{count} символов<br>',
  deleteAndEdit: 'Удаление и редактирование (в том числе URL ссылки)',
  notAlwaysAvailable: 'Не всегда доступно',
  free: 'Бесплатно',
  entirePlacementPeriod: 'Весь срок размещения',
  linkSettings: 'Настройки ссылки',
  pageMetaTags: 'Мета-теги страницы',
  not: 'НЕТ',
  weGuarantee: 'Гарантируем на год',
  weGuaranteeTexts: {
    1: 'Ссылка всегда отображается<br>на странице',
    2: 'Ссылка видна в исходном коде<br>и открыта для индексации',
    3: 'Нет чужих ссылок на странице',
    4: 'Сайт-донор не станет<br>заспамленным',
    5: 'вернем деньги на баланс, если<br>«что-то пойдет не так»',
  },
  ourDonorSites: 'Наши сайты-доноры',
  doNotSellLinks: '<b>Не продают</b> ссылки в других биржах',
  placementCostDependsOnSite:
    '<b>Стоимость размещения</b> зависит напрямую от показателей сайта донора ( одинаковая для ссылки и статьи )',
  cis: 'СНГ',
  foreign: 'зарубежные',
  domain: 'Домен',
  iks: 'ИКС',
  visitsPerMonth: 'Визиты в мес.',
  inTheIndex: 'В индексе',
  siteAge: 'Возраст сайта',
  mozRank: 'MozRank',
  majesticSeo: 'MajesticSeo',
  cfTf: 'CF / TF',
  DMOZ: 'DMOZ',
  acceptors: 'Акцепторы',
  donors: 'Доноры',
  spamming: 'Заспам.',
  price: 'Цена',
  comparisonWithCompetitors: 'Сравнение с конкурентами',
  sitesSpamming: 'Заспамленность площадок',
  sitesSpammingTexts: {
    trastik: 'Доноров в среднем на 400% больше чем акцепторов',
    goGetLinks: 'Акцепторов, в среднем на 35% больше, чем доноров',
    prSape: 'Акцепторов, в среднем, в 2.6 раза больше, чем доноров',
  },
  inboundLinks: 'входящие ссылки',
  permalinkPrices: 'Цены на вечные ссылки',
  outgoingLinks: 'исходящие ссылки',
  registration: 'Регистрация',
  phone: 'Телефон',
  getSMS: 'Получить SMS',
  confirmNumberForBonus: 'Подтвердите номер, чтобы получить бонус 200 ₽',
  footerTexts: {
    1: 'Работаем с<br><b>2013</b> года',
    2: 'Мы разместили<br><b>{count}</b> ссылок',
    3: 'На <b>{count}</b> сайтах',
    4: 'Для <b>{count}</b> клиентов',
  },
  yes: 'Да',
  countryCode: 'Код страны',
  sendSMSMore: 'Выслать SMS повторно',
  validate: {
    required: 'Поле обязательно для заполнения',
    email: 'Введите E-mail',
  },
  recovery: 'Восстановить',
  recoveryPassword: 'Восстановить<br>пароль',
  recoveryPasswordSuccessText:
    'На указанный E-mail отправлено письмо для продолжения восстановления пароля',
  submit: 'Отправить',
  somethingWentWrong: 'Что-то пошло не так',
};

<template>
  <div class="lang-select">
    <nuxt-link
      v-for="lang in locales"
      :key="lang"
      :class="{lang:true,current:$i18n.locale==lang}"
      :lang="lang"
      :to="switchLocalePath(lang)"
    >
      {{ $t(`languages.${lang}`) }}
    </nuxt-link>
  </div>
</template>

<script>
import { find, get } from 'lodash';
import config from '../nuxt.config';

export default {
  name: 'LangSelect',
  computed: {
    i18nConfig: () => find(config.modules, module => module[0] === 'nuxt-i18n'),
    locales() {
      return get(this.i18nConfig, '[1].locales');
    },
  },
};
</script>

<style lang="less" scoped>
.lang-select {
  position: relative;

  &:hover {
    .list {
      display: flex;
    }
  }

  .current {
    background: #fffced;
    box-shadow: 0 4px 4px #e7ded2, inset 0 2px 0 #ffffff;
    border-radius: 8px;

    .lang {
      padding-right: 25px;

      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 5px;
        background-image: url('~/assets/img/select-arrow.svg');
        position: absolute;
        top: 50%;
        right: 7px;
      }
    }
  }

  .list {
    flex-direction: column;
    background: linear-gradient(180deg, #ffffff 0%, #fffef6 100%);
    box-shadow: 0 10px 20px #f7ecd8, 0 6px 4px rgba(186, 160, 133, 0.05);
    border-radius: 6px;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    overflow: hidden;

    .lang {
      cursor: pointer;

      &:hover {
        color: #fff;
        background-color: #c6ae97;
      }
    }
  }

  .lang {
    padding: 5px 23px 5px 40px;
    background-repeat: no-repeat;
    background-position: 10px center;
    user-select: none;
    background-size: 24px 16px;
    line-height: 20px;
    font-size: 18px;
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    &[lang='ru'] {
      background-image: url('~/assets/img/flags/ru.svg');
    }

    &[lang='en'] {
      background-image: url('~/assets/img/flags/en.svg');
    }
  }
}
</style>

<template>
  <form class="registration" novalidate @submit.prevent="submit">
    <ValidationObserver ref="validator">
      <div class="section-image"></div>
      <h2>{{ $t('registration') }}:</h2>
      <div class="form">
        <div class="col">
          <div class="row">
            <div class="label">
              <label for="reg-email">{{ $t('email') }}</label>
              <span class="req">*</span>
            </div>
            <div class="input">
              <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
                <span v-show="errors.length && submitted" class="error">{{ errors[0] }}</span>
                <input
                  id="reg-email"
                  ref="regEmail"
                  v-model.trim="form.email"
                  autocomplete="off"
                  type="email"
                  name="email"
                  maxlength="40"
                  required
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="label">
              <label for="reg-name">{{ $t('name') }}</label>
              <span class="req">*</span>
            </div>
            <div class="input">
              <ValidationProvider v-slot="{ errors }" name="name" rules="required">
                <span v-show="errors.length && submitted" class="error">{{ errors[0] }}</span>
                <input
                  id="reg-name"
                  ref="regName"
                  v-model.trim="form.name"
                  autocomplete="off"
                  type="text"
                  name="name"
                  maxlength="40"
                  required
                />
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="label">
              <label for="reg-password">{{ $t('password') }}</label>
              <span class="req">*</span>
            </div>
            <div class="input">
              <div class="show-password-eye" @click="showPassword = !showPassword"></div>
              <ValidationProvider v-slot="{ errors }" name="password" rules="required">
                <span v-show="errors.length && submitted" class="error">{{ errors[0] }}</span>
                <input
                  id="reg-password"
                  ref="regPassword"
                  v-model.trim="form.password"
                  autocomplete="off"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  maxlength="20"
                  required
                />
              </ValidationProvider>
            </div>
          </div>
        </div>
        <!--<div class="col">-->
        <!--<div class="row">-->
        <!--<div class="label">-->
        <!--<label for="reg-phone">{{ $t('phone') }}</label>-->
        <!--</div>-->
        <!--<div class="input">-->
        <!--<input-->
        <!--id="reg-phone"-->
        <!--ref="regPhone"-->
        <!--v-model="form.phone"-->
        <!--v-mask="'+7 (###) ###-##-##'"-->
        <!--autocomplete="off"-->
        <!--placeholder="+7 (___) ___-__-__"-->
        <!--type="tel"-->
        <!--name="phone"-->
        <!--/>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="row bonus">-->
        <!--<div class="help">{{ $t('confirmNumberForBonus') }}</div>-->
        <!--<button v-show="!showSMSInput" type="button" class="btn btn-blue" @click="getSMS">-->
        <!--{{ $t('getSMS') }}-->
        <!--</button>-->
        <!--<div v-show="showSMSInput">-->
        <!--<div class="input">-->
        <!--<input-->
        <!--id="reg-sms"-->
        <!--ref="smsInput"-->
        <!--v-model="form.sms"-->
        <!--v-mask="'#-#-#-#'"-->
        <!--autocomplete="off"-->
        <!--type="text"-->
        <!--name="sms"-->
        <!--/>-->
        <!--</div>-->
        <!--<div class="send-more">{{ $t('sendSMSMore') }}</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
      </div>
      <div id="recaptcha2_reg_row">
        <div id="recaptcha2_reg"></div>
      </div>
      <div class="start">
        <button
          type="submit"
          class="btn btn-orange"
          :disabled="!form.email || !form.password || isButtonDisabled"
        >
          {{ $t('startPromotion') }}
        </button>
      </div>
    </ValidationObserver>
  </form>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {mask} from 'vue-the-mask'
import axios from 'axios'

export default {
  name: 'Registration',
  directives: {mask},
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      v2: false,
      submitted: false,
      showPassword: false,
      showSMSInput: false,
      isButtonDisabled: false,
      form: {
        email: null,
        name: null,
        password: null,
        phone: null,
      },
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
  },
  methods: {
    setCookie(name, value, days) {
      const d = new Date()
      d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
      document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`
    },
    getCookie(cname) {
      const name = `${cname}=`
      const decodedCookie = decodeURIComponent(document.cookie)
      const ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    async submit() {
      this.isButtonDisabled = true
      this.$refs.validator.validate()
      this.submitted = true

      let token = ''
      if (this.v2) {
        const res = document.querySelector('#recaptcha2_reg [id^="g-recaptcha-response"]')
        if (!res || !res.value.length) {
          return
        }
        token = res.value
      } else {
        await this.$recaptchaLoaded()
        token = await this.$recaptcha('login')
      }

      const fd = new FormData()

      fd.set('gtoken', token)
      fd.set('v2', this.v2 ? '1' : '0')

      fd.set('email', this.$refs.regEmail.value)
      fd.set('password', this.$refs.regPassword.value)
      fd.set('name', this.$refs.regName.value)
      fd.set('ref', this.getCookie('ref'))

      axios({
        method: 'post',
        url: `${this.$config.serverUrl}/${this.lang}/app/modal/account.php?id=9`,
        data: fd,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
        .then(result => {
          if (result.data.status === 'success') {
            this.setCookie('ref', '', -1)
            window.open(
              `${this.lang}/?do_login=${result.data.userId}&hash=${result.data.hash}`,
              '_self',
            )
          } else {
            this.isButtonDisabled = false
            console.log(JSON.stringify(result))
            if (result.data.lowscore) {
              this.v2 = grecaptcha.render('recaptcha2_reg', {
                sitekey: this.$config.recaptchaPublicKeyV2,
              })
            } else {
              if (this.v2) {
                grecaptcha.reset(this.v2)
              }
              const msg = (result.data.message || result.data.text || JSON.stringify(result.data))
                .replace('<b>', '')
                .replace('</b>', ' ')
                .replace('<br/>', '\n')
              if (this.$refs.validator.errors[result.data.inputName]) {
                this.$refs.validator.errors[result.data.inputName].push(msg)
              } else {
                alert(msg)
              }
            }
          }
        })
        .catch(response => {
          // handle error
          this.isButtonDisabled = false
          console.error(response)
        })
    },
    getSMS() {
      this.showSMSInput = true
      this.$nextTick(() => {
        this.$refs.smsInput.focus()
      })
    },
  },
}
</script>

<style lang="less" scoped>
#recaptcha2_reg_row {
  text-align: center;

  #recaptcha2_reg {
    display: inline-block;
  }
}

.registration {
  .section-image {
    background-image: url('~/assets/img/reg.svg');
  }

  .form {
    @label-width: 75px;

    display: flex;
    justify-content: space-around;

    .col {
      width: 465px;

      .row {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .label {
          width: @label-width;

          .req {
            color: #e56d0e;
          }
        }

        .input {
          flex-grow: 1;
          position: relative;

          input {
            width: 100%;
          }
        }

        &.bonus {
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: -10px;

          .help {
            font-size: 14px;
            line-height: 24px;
            color: #000000;
            margin-left: @label-width;
            width: 175px;
          }

          button {
            text-transform: uppercase;
            margin-top: 8px;
          }

          .input {
            input {
              width: 128px;
            }
          }

          .send-more {
            margin-top: 8px;
            text-align: right;
            text-decoration-line: underline;
            color: #3774ce;
            font-size: 14px;
            line-height: 24px;
            user-select: none;
            cursor: pointer;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .start {
    text-align: center;
    margin-top: 20px;

    button {
      text-transform: uppercase;
      width: 270px;
      height: 48px;
      line-height: 48px;
      outline: none;
    }
  }
}
</style>

export default {
  title: 'Link building for half the market price. One time fee - unlimited benefits',
  meta: {
    description:
      'Link Building for any niche/topic! We place. Indexed by Google. Your search engine<br>position ranking improves',
    keywords: 'Link Building, links, articles, buy links',
  },
  slogan: 'Link building for half the market price',
  languages: {
    ru: 'рус',
    en: 'eng',
  },
  startPromotion: 'Start the promotion',
  login: 'Login',
  loginInCabinet: 'Login in cabinet',
  email: 'E-mail',
  password: 'Password',
  name: 'Name',
  forgotPassword: 'Forgot password?',
  promote: 'Link',
  sites: 'Building',
  any: 'for any',
  subjects: 'niche/topic!',
  topTexts: {
    1: 'We place',
    2: 'Indexed by Google',
    3: 'Your search engine<br>position ranking improves',
  },
  links: 'Links',
  and: 'And',
  articles: 'Articles',
  placementPeriod: 'Placement period',
  whileSiteWorking: 'Permanent',
  placementPages: 'Placement pages',
  alreadyCreated: 'Existing',
  new: 'New',
  pageIndexing: 'Google index',
  alreadyInIndex: 'Indexed already',
  warrantyUpTo70days: 'Usually up to 70 days',
  surroundingText: 'Text limit',
  xSymbols: '{count} characters<br/>+ images',
  xSymbolsLinks: '{count} characters<br>',
  deleteAndEdit: 'Cost to delete link or edit text/URL',
  deleteAndEditPriceForLinks: '$ 1.54',
  notAlwaysAvailable: '',
  free: 'Free',
  entirePlacementPeriod: '',
  linkSettings: 'Link setup',
  pageMetaTags: 'Page meta tags',
  not: 'No',
  weGuarantee: 'Our one-year guarantee',
  weGuaranteeTexts: {
    1: 'Link is always displayed<br>on the page',
    2: 'Link is placed in a source code and is available for indexation',
    3: 'No other ad links on the page',
    4: 'Stable page index ranking ',
    5: 'Money back to the account if something goes wrong',
  },
  ourDonorSites: 'Our advertising platforms',
  doNotSellLinks: 'Are selling links <b>via our service only</b>',
  placementCostDependsOnSite:
    '<b>Placement cost</b> reflects advertising platform ranking (same cost for article/link)</br>',
  cis: 'CIS',
  foreign: 'International',
  domain: 'Domain',
  iks: 'IQS',
  visitsPerMonth: 'Visits per month',
  inTheIndex: 'In the index',
  siteAge: 'Site age',
  mozRank: 'MozRank',
  majesticSeo: 'MajesticSeo',
  cfTf: 'CF / TF',
  DMOZ: 'DMOZ',
  acceptors: 'Acceptors',
  donors: 'Donors',
  spamming: 'Spamming',
  price: 'Price',
  comparisonWithCompetitors: 'Comparision to competitors',
  sitesSpamming: 'Spamming',
  sitesSpammingTexts: {
    trastik: '4 times more donors than acceptors',
    goGetLinks: '1.5 times less donors than acceptors',
    prSape: '2.3 times less donors than acceptors',
  },
  inboundLinks: 'inbound links',
  permalinkPrices: 'Permalink prices',
  outgoingLinks: 'outbound links',
  registration: 'Sign up',
  phone: 'Phone call',
  getSMS: 'SMS',
  confirmNumberForBonus: 'Confirm your number and get $5 bonus',
  footerTexts: {
    1: 'Est. <b>2013</b>',
    2: '<b>{count}</b> links placed',
    3: 'On <b>{count}</b> sites',
    4: 'For <b>{count}</b> clients',
  },
  yes: 'Yes',
  countryCode: 'Country code',
  sendSMSMore: 'Resend SMS',
  validate: {
    required: 'Input required',
    email: 'E-mail',
  },
  recovery: 'Recovery',
  recoveryPassword: 'Recovery<br>password',
  recoveryPasswordSuccessText: 'Password recovery e-mail sent',
  submit: 'Submit',
  somethingWentWrong: 'Something went wrong!',
};

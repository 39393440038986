<template>
  <footer>
    <ul>
      <li v-html="$t('footerTexts[1]')"></li>
      <li v-html="$t('footerTexts[2]', { count: formatFooterNumbers(footerData.urlCount) })"></li>
      <li v-html="$t('footerTexts[3]', { count: formatFooterNumbers(footerData.sitesCount) })"></li>
      <li v-html="$t('footerTexts[4]', { count: formatFooterNumbers(footerData.usersCount) })"></li>
    </ul>
    <div class="logo-and-slogan">{{ $t('slogan') }}</div>
  </footer>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Footer',
  data() {
    return {
      footerData: [],
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    axios({
      method: 'get',
      url: `${this.$config.serverUrl}/${this.lang}/app/modal/account.php?id=12`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
      .then(response => {
        this.footerData = response.data;
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  },
  methods: {
    formatFooterNumbers(value) {
      let str = `${value}`;
      let result = '';
      const count = str.length % 3;
      if (str.length > 3) {
        if (count === 0) {
          for (let i = 0; i < str.length / 3; i++) {
            result += `${str.slice(i * 3, (i + 1) * 3)} `;
          }
        } else {
          const start = str.slice(0, count);
          result += `${start} `;
          str = str.slice(count, str.length);
          for (let i = 0; i < str.length / 3; i++) {
            result += `${str.slice(i * 3, (i + 1) * 3)} `;
          }
        }
      } else {
        result = str;
      }
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
footer {
  background-image: url('~/assets/img/footer.svg'), url('~/assets/img/footer-gradient.png');
  background-repeat: no-repeat, repeat-x;
  background-position: center center, left bottom;
  height: 633px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #5b4941;
  min-width: 1170px;
  margin-top: 30px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto 40px;
    display: flex;
    border: 1px solid #fff;
    border-radius: 40px;
    height: 70px;
    width: 910px;
    align-items: center;
    justify-content: space-around;

    li {
      margin: 0;
      padding: 10px 0 10px 40px;
      background-position: left center;
      background-repeat: no-repeat;

      &:nth-child(1) {
        background-image: url('~/assets/img/calendar.svg');
      }

      &:nth-child(2) {
        background-image: url('~/assets/img/link.svg');
      }

      &:nth-child(3) {
        background-image: url('~/assets/img/site.svg');
      }

      &:nth-child(4) {
        background-image: url('~/assets/img/client.svg');
      }
    }
  }

  .logo-and-slogan {
    background-image: url('~/assets/img/footer-logo.svg');
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding-top: 35px;
    background-position: top center;
    background-repeat: no-repeat;
  }
}
</style>

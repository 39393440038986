<template>
  <div v-if="authOpened" class="auth-dialog">
    <div class="dialog-window" :class="{ visible: windowVisible }">
      <div
        class="dialog-header"
        :class="{ 'lock-icon': recoveryPasswordMode, 'key-icon': !recoveryPasswordMode }"
      >
        <div
          class="dialog-header-title"
          v-html="$t(recoveryPasswordMode ? 'recoveryPassword' : 'loginInCabinet')"
        ></div>
        <div class="dialog-header-close" @click="closeAuth"></div>
      </div>
      <div class="dialog-main">
        <div v-show="message === 'recoveryPasswordSuccess'" class="message">
          {{ $t('recoveryPasswordSuccessText') }}
        </div>

        <div v-if="!message">
          <form v-if="recoveryPasswordMode" novalidate @submit.prevent="recoveryPassword">
            <ValidationObserver ref="recoveryPasswordValidator">
              <div class="row">
                <div class="label">
                  <label for="recovery-email">{{ $t('email') }}</label>
                </div>
                <div class="input">
                  <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
                    <span v-show="errors.length && submitted" class="error">{{ errors[0] }}</span>
                    <input
                      id="recovery-email"
                      ref="recoveryEmail"
                      v-model.trim="recoveryPasswordForm.email"
                      type="email"
                      name="email"
                      required
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div class="buttons right">
                <button type="submit" class="btn btn-orange">{{ $t('recovery') }}</button>
              </div>
            </ValidationObserver>
          </form>
          <form v-else novalidate @submit.prevent="auth">
            <ValidationObserver ref="authValidator">
              <div class="row">
                <div class="label">
                  <label for="auth-email">{{ $t('email') }}</label>
                </div>
                <div class="input">
                  <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
                    <span v-show="errors.length && submitted" class="error">{{ errors[0] }}</span>
                    <input
                      id="auth-email"
                      ref="authMail"
                      v-model.trim="authForm.email"
                      type="email"
                      name="email"
                      required
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="label">
                  <label for="auth-password">{{ $t('password') }}</label>
                </div>
                <div class="input">
                  <div class="show-password-eye" @click="showPassword = !showPassword"></div>
                  <ValidationProvider v-slot="{ errors }" name="password" rules="required">
                    <span v-show="errors.length && submitted" class="error">{{ errors[0] }}</span>
                    <input
                      id="auth-password"
                      ref="authPassword"
                      v-model.trim="authForm.password"
                      :type="showPassword ? 'text' : 'password'"
                      name="password"
                      maxlength="20"
                      required
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div class="recaptchaRow">
                <div id="recaptcha2"></div>
              </div>
              <div class="buttons">
                <div class="link-btn" @click="recoveryPasswordMode = true">
                  {{ $t('forgotPassword') }}
                </div>
                <button type="submit" class="btn btn-orange">{{ $t('login') }}</button>
              </div>
            </ValidationObserver>
          </form>
        </div>
      </div>
    </div>
    <div class="dialog-overlay"></div>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {mapState, mapMutations} from 'vuex'
import axios from 'axios'

export default {
  name: 'AuthDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      v2: false,
      submitted: false,
      recoveryPasswordMode: false,
      message: null,
      showPassword: false,
      authForm: {
        email: null,
        password: null,
      },
      recoveryPasswordForm: {
        email: null,
      },
      windowVisible: false,
    }
  },
  computed: {
    ...mapState(['authOpened']),
    lang() {
      return this.$i18n.locale
    },
  },
  watch: {
    authOpened(authOpened) {
      this.submitted = false
      this.recoveryPasswordMode = false
      this.message = null

      this.showPassword = false
      this.authForm.email = null
      this.authForm.password = null

      this.recoveryPasswordForm.email = null

      if (authOpened) {
        setTimeout(() => (this.windowVisible = true), 100)
      } else {
        this.windowVisible = false
      }
    },
    recoveryPasswordMode() {
      this.submitted = false
    },
  },
  methods: {
    ...mapMutations(['closeAuth']),
    async auth() {
      this.submitted = true
      this.$refs.authValidator.validate()
      try {
        await this.recaptcha()
      } catch (e) {
        console.error(e)
        alert(this.$t('somethingWentWrong'))
      }
    },
    async recoveryPassword() {
      this.submitted = true
      this.$refs.recoveryPasswordValidator.validate().then(v => {
        if (v) this.message = 'recoveryPasswordSuccess'
      })

      const fd = new FormData()
      fd.set('recovery_mail', this.$refs.recoveryEmail.value)
      const result = await axios({
        method: 'post',
        url: `${this.$config.serverUrl}/${this.lang}/app/modal/account.php?id=8`,
        data: fd,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
      if (result.data.status === 'success') {
        this.closeAuth()
      } else {
        const msg = result.data.message
          .replace('<b>', '')
          .replace('</b>', ' ')
          .replace('<br/>', '\n')
        if (!this.$refs.authValidator.errors[result.data.inputName]) {
          alert(msg)
        } else {
          this.$refs.recoveryPasswordValidator.errors[result.data.inputName].push(msg)
        }
      }
    },
    async recaptcha() {
      let token = ''
      if (this.v2) {
        const res = document.querySelector('#recaptcha2 [id^="g-recaptcha-response"]')
        if (!res || !res.value.length) {
          return
        }
        token = res.value
      } else {
        await this.$recaptchaLoaded()
        token = await this.$recaptcha('login')
      }
      const fd = new FormData()

      fd.set('gtoken', token)
      fd.set('v2', this.v2 ? '1' : '0')

      fd.set('mail', this.$refs.authMail.value)
      fd.set('password', this.$refs.authPassword.value)
      try {
        const result = await axios({
          method: 'post',
          url: `${this.$config.serverUrl}/${this.lang}/app/modal/account.php?id=1&json=1`,
          data: fd,
          headers: {'Content-Type': 'multipart/form-data'},
        })
        console.log(JSON.stringify(result))
        if (result.data.style === 'green') {
          window.open(
            `${this.lang}/?do_login=${result.data.user_id}&hash=${result.data.hash}`,
            '_self',
          )
        } else if (result.data.lowscore) {
          this.v2 = grecaptcha.render('recaptcha2', {
            sitekey: this.$config.recaptchaPublicKeyV2,
          })
        } else {
          if (this.v2) {
            grecaptcha.reset(this.v2)
          }
          const msg = result.data.text
            .replace('<b>', '')
            .replace('</b>', ' ')
            .replace('<br/>', '\n')

          if (!this.$refs.authValidator.errors[result.data.inputName]) {
            alert(msg)
          } else {
            this.$refs.authValidator.errors[result.data.inputName].push(msg)
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.recaptchaRow {
  text-align: center;
  margin-bottom: 24px;
}

#recaptcha2 {
  display: inline-block;
}

.auth-dialog {
  .dialog-window {
    opacity: 0;
    transform: scale(0.75);
    transition: all 0.2s ease;
    z-index: 550;

    &.visible {
      opacity: 1;
      transform: scale(1);
    }

    .dialog-main {
      @labels-width: 85px;

      .row {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .label {
          width: @labels-width;
        }

        .input {
          flex-grow: 1;
          position: relative;

          input {
            width: 100%;
          }
        }
      }

      .buttons {
        display: flex;
        margin-left: @labels-width;
        justify-content: space-between;
        align-items: center;

        &.right {
          justify-content: flex-end;
        }

        button {
          width: 170px;
          height: 48px;
          line-height: 48px;
          text-transform: uppercase;
          outline: none;
        }
      }
    }
  }

  .message {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin: 25px 0;
  }
}
</style>

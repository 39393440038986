<template>
  <div class="we-guarantee">
    <h2>{{ $t('weGuarantee') }}:</h2>
    <div class="texts">
      <div v-html="$t('weGuaranteeTexts.1')"></div>
      <div v-html="$t('weGuaranteeTexts.2')"></div>
      <div v-html="$t('weGuaranteeTexts.3')"></div>
      <div v-html="$t('weGuaranteeTexts.4')"></div>
      <div v-html="$t('weGuaranteeTexts.5')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeGuarantee',
};
</script>

<style lang="less" scoped>
.we-guarantee {
  background-image: url('~/assets/img/we-guarantee-img.svg');
  background-position: top center;
  background-repeat: no-repeat;
  width: 1100px;
  height: 535px;
  margin: 0 auto;
  padding-top: 55px;
  position: relative;

  h2 {
    font-size: 32px;
  }

  .texts {
    > div {
      position: absolute;
      line-height: 24px;

      &:nth-child(1) {
        text-align: right;
        right: 50%;
        margin-right: 325px;
        top: 165px;
      }

      &:nth-child(2) {
        left: 50%;
        margin-left: 315px;
        top: 165px;
      }

      &:nth-child(3) {
        text-align: right;
        right: 50%;
        top: 363px;
        margin-right: 245px;
      }

      &:nth-child(4) {
        left: 50%;
        margin-left: 230px;
        top: 350px;
      }

      &:nth-child(5) {
        font-size: 24px;
        line-height: 32px;
        bottom: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/we-guarantee-img.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".we-guarantee[data-v-b5a7edca]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:top;background-repeat:no-repeat;height:535px;margin:0 auto;padding-top:55px;position:relative;width:1100px}.we-guarantee h2[data-v-b5a7edca]{font-size:32px}.we-guarantee .texts>div[data-v-b5a7edca]{line-height:24px;position:absolute}.we-guarantee .texts>div[data-v-b5a7edca]:first-child{margin-right:325px;right:50%;text-align:right;top:165px}.we-guarantee .texts>div[data-v-b5a7edca]:nth-child(2){left:50%;margin-left:315px;top:165px}.we-guarantee .texts>div[data-v-b5a7edca]:nth-child(3){margin-right:245px;right:50%;text-align:right;top:363px}.we-guarantee .texts>div[data-v-b5a7edca]:nth-child(4){left:50%;margin-left:230px;top:350px}.we-guarantee .texts>div[data-v-b5a7edca]:nth-child(5){bottom:0;font-size:24px;line-height:32px;text-align:center;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

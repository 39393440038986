<template>
  <div class="parallax">
    <img
      src="~/assets/img/side-elements/1.png"
      alt=""
      :style="{ marginTop: `${scrollPos / 2.07}px` }"
    />
    <img
      src="~/assets/img/side-elements/2.png"
      alt=""
      :style="{ marginTop: `${scrollPos / 2.1}px` }"
    />
    <img
      src="~/assets/img/side-elements/3.png"
      alt=""
      :style="{ marginTop: `-${scrollPos / 1.3}px` }"
    />
    <img
      src="~/assets/img/side-elements/4.png"
      alt=""
      :style="{ marginTop: `-${scrollPos / 10}px` }"
    />
    <img
      src="~/assets/img/side-elements/5.png"
      alt=""
      :style="{ display: `none`, marginTop: `${scrollPos / 1.85}px` }"
    />
    <img
      src="~/assets/img/side-elements/6.png"
      alt=""
      :style="{ marginTop: `-${scrollPos / 2}px` }"
    />
    <img
      src="~/assets/img/side-elements/7.png"
      alt=""
      :style="{ marginTop: `-${scrollPos / 2}px` }"
    />
    <!--    <img-->
    <!--      src="~/assets/img/side-elements/8.png"-->
    <!--      alt=""-->
    <!--      :style="{ marginTop: `${scrollPos / 5}px` }"-->
    <!--    />-->
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Parallax',
  computed: {
    ...mapState(['scrollPos']),
  },
};
</script>

<style lang="less" scoped>
.parallax {
  user-select: none;

  @media (max-width: 1550px) {
    display: none;
  }

  img {
    position: absolute;
    left: 50%;
    z-index: 200;

    &:nth-child(1) {
      margin-left: -995px;
      top: 470px;
    }

    &:nth-child(2) {
      margin-left: 800px;
      top: 170px;
    }

    &:nth-child(3) {
      margin-left: -1050px;
      top: 3000px;
    }

    &:nth-child(4) {
      margin-left: 640px;
      top: 4100px;
    }

    &:nth-child(5) {
      margin-left: -1100px;
      top: 2450px;
    }

    &:nth-child(6) {
      margin-left: 710px;
      top: 3400px;
    }

    &:nth-child(7) {
      margin-left: -1240px;
      top: 5100px;
    }

    &:nth-child(8) {
      margin-left: 940px;
      top: 3750px;
    }

    &:nth-child(9) {
      margin-left: 820px;
      top: 5500px;
    }
  }
}
</style>

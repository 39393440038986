<template>
  <div ref="comparison" class="comparison">
    <div class="section-image"></div>
    <h2>{{ $t('comparisonWithCompetitors') }}:</h2>

    <div class="charts">
      <div class="col sites-spamming">
        <div class="col-title">{{ $t('sitesSpamming') }}</div>
        <div class="help">
          <div class="donors">
            <div>{{ $t('donors') }}</div>
            <small>({{ $t('inboundLinks') }})</small>
          </div>
          <div class="acceptors">
            <div>{{ $t('acceptors') }}</div>
            <small>({{ $t('outgoingLinks') }})</small>
          </div>
        </div>
        <div class="comparison-charts">
          <div class="chart chart-trastik">
            <div class="company-logo"></div>
            <div class="description">{{ $t('sitesSpammingTexts.trastik') }}</div>
          </div>
          <div class="chart chart-go-get-links">
            <div class="company-logo"></div>
            <div class="description">{{ $t('sitesSpammingTexts.goGetLinks') }}</div>
          </div>
          <div class="chart chart-pr-sape">
            <div class="company-logo"></div>
            <div class="description">{{ $t('sitesSpammingTexts.prSape') }}</div>
          </div>
        </div>
      </div>
      <div class="col permalink-prices">
        <div class="col-title">{{ $t('permalinkPrices') }}</div>
        <div class="chart">
          <div class="iks">{{ $t('iks') }}</div>
          <div class="values">
            <div class="group">
              <div v-tooltip="'129 ₽'" class="value" style="height: 16px;"></div>
              <div v-tooltip="'190 ₽'" class="value" style="height: 25px;"></div>
              <div v-tooltip="'280 ₽'" class="value" style="height: 31px;"></div>
            </div>
            <div class="group">
              <div v-tooltip="'145 ₽'" class="value" style="height: 19px;"></div>
              <div v-tooltip="'268 ₽'" class="value" style="height: 31px;"></div>
              <div v-tooltip="'409 ₽'" class="value" style="height: 56px;"></div>
            </div>
            <div class="group">
              <div v-tooltip="'208 ₽'" class="value" style="height: 28px;"></div>
              <div v-tooltip="'366 ₽'" class="value" style="height: 45px;"></div>
              <div v-tooltip="'619 ₽'" class="value" style="height: 73px;"></div>
            </div>
            <div class="group">
              <div v-tooltip="'294 ₽'" class="value" style="height: 45px;"></div>
              <div v-tooltip="'477 ₽'" class="value" style="height: 73px;"></div>
              <div v-tooltip="'913 ₽'" class="value" style="height: 97px;"></div>
            </div>
            <div class="group">
              <div v-tooltip="'478 ₽'" class="value" style="height: 49px;"></div>
              <div v-tooltip="'695 ₽'" class="value" style="height: 97px;"></div>
              <div v-tooltip="'1461 ₽'" class="value" style="height: 170px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Comparison',
};
</script>

<style lang="less" scoped>
.comparison {
  .section-image {
    background-image: url('~/assets/img/comparison.svg');
  }

  .charts {
    width: 1090px;
    margin: 0 auto;
    display: flex;

    > .col {
      width: 50%;

      .col-title {
        font-size: 24px;
        margin-bottom: 25px;
      }

      &.sites-spamming {
        margin-right: 25px;

        .help {
          display: flex;
          margin-bottom: 24px;

          > div {
            width: 160px;
            background-repeat: no-repeat;
            background-position: left center;

            &.donors {
              background-image: url('~/assets/img/chart-help-1.svg');
              padding-left: 45px;
            }

            &.acceptors {
              background-image: url('~/assets/img/chart-help-2.svg');
              padding-left: 30px;
            }
          }
        }

        .comparison-charts {
          display: flex;

          .chart {
            width: 115px;
            padding-top: 125px;
            background-position: top center;
            background-repeat: no-repeat;
            box-sizing: border-box;
            transition: all 0.6s ease;

            &:not(:last-child) {
              margin-right: 64px;
            }

            .company-logo {
              background-size: 100% auto;
              height: 35px;
              background-repeat: no-repeat;
              background-position: center center;
              margin-left: 17px;
            }

            &.chart-trastik {
              background-image: url('~/assets/img/chart-trastik.svg');
              transition-delay: 0s;

              .company-logo {
                width: 71px;
                background-image: url('~/assets/img/logo-mini.svg');
              }
            }

            &.chart-go-get-links {
              background-image: url('~/assets/img/chart-go-get-links.svg');
              transition-delay: 0.2s;

              .company-logo {
                width: 54px;
                background-image: url('~/assets/img/go-get-links-logo.svg');
              }
            }

            &.chart-pr-sape {
              background-image: url('~/assets/img/chart-pr-sape.svg');
              transition-delay: 0.4s;

              .company-logo {
                width: 80px;
                background-image: url('~/assets/img/pr-sape-logo.svg');
              }
            }

            .description {
              font-family: 'PT Sans Caption', sans-serif;
              font-size: 12px;
              line-height: 16px;
              margin: 10px -17px 0 17px;
            }
          }
        }
      }

      &.permalink-prices {
        margin-left: 25px;

        .chart {
          width: 540px;
          height: 304px;
          background-position: left bottom;
          background-repeat: no-repeat;
          background-image: url('~/assets/img/permalink-prices-chart.svg');
          position: relative;

          .iks {
            position: absolute;
            bottom: -3px;
            left: 10px;
            color: #b93d01;
            cursor: default;
            user-select: none;
          }

          .values {
            display: flex;
            position: absolute;
            left: 45px;
            bottom: 32px;

            .group {
              @init-delay: 0.5s;
              @next-delay: 0.15s;

              display: flex;
              align-items: flex-end;
              margin-right: 22px;
              transform-origin: bottom;
              transition: transform 0.4s ease;

              &:nth-child(1) {
                transition-delay: @next-delay * 0 + @init-delay;
              }

              &:nth-child(2) {
                transition-delay: @next-delay * 1 + @init-delay;
              }

              &:nth-child(3) {
                transition-delay: @next-delay * 2 + @init-delay;
              }

              &:nth-child(4) {
                transition-delay: @next-delay * 3 + @init-delay;
              }

              &:nth-child(5) {
                transition-delay: @next-delay * 4 + @init-delay;
              }

              .value {
                margin-right: 1px;
                width: 24px;
                border-radius: 4px 4px 0 0;

                &:nth-child(1) {
                  background: linear-gradient(76.96deg, #e56d0e 0%, #f79c2a 100%);
                }

                &:nth-child(2) {
                  background-color: #9d9483;
                }

                &:nth-child(3) {
                  background-color: #5c5447;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/reg.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#recaptcha2_reg_row[data-v-febb0488]{text-align:center}#recaptcha2_reg_row #recaptcha2_reg[data-v-febb0488]{display:inline-block}.registration .section-image[data-v-febb0488]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.registration .form[data-v-febb0488]{display:flex;justify-content:space-around}.registration .form .col[data-v-febb0488]{width:465px}.registration .form .col .row[data-v-febb0488]{align-items:center;display:flex;margin-bottom:24px}.registration .form .col .row .label[data-v-febb0488]{width:75px}.registration .form .col .row .label .req[data-v-febb0488]{color:#e56d0e}.registration .form .col .row .input[data-v-febb0488]{flex-grow:1;position:relative}.registration .form .col .row .input input[data-v-febb0488]{width:100%}.registration .form .col .row.bonus[data-v-febb0488]{align-items:flex-start;justify-content:space-between;margin-bottom:-10px}.registration .form .col .row.bonus .help[data-v-febb0488]{color:#000;font-size:14px;line-height:24px;margin-left:75px;width:175px}.registration .form .col .row.bonus button[data-v-febb0488]{margin-top:8px;text-transform:uppercase}.registration .form .col .row.bonus .input input[data-v-febb0488]{width:128px}.registration .form .col .row.bonus .send-more[data-v-febb0488]{color:#3774ce;cursor:pointer;font-size:14px;line-height:24px;margin-top:8px;text-align:right;text-decoration-line:underline;-webkit-user-select:none;-moz-user-select:none;user-select:none}.registration .form .col .row.bonus .send-more[data-v-febb0488]:hover{-webkit-text-decoration:none;text-decoration:none}.registration .start[data-v-febb0488]{margin-top:20px;text-align:center}.registration .start button[data-v-febb0488]{height:48px;line-height:48px;outline:none;text-transform:uppercase;width:270px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

/* eslint-disable */

import ru from './lang/ru'
import en from './lang/en'

export default {
  mode: 'universal',
  dev: false,
  publicRuntimeConfig: {
    recaptchaPublicKeyV3: process.env.RECAPTCHA_PUBLIC_KEY_V3,
    recaptchaPublicKeyV2: process.env.RECAPTCHA_PUBLIC_KEY_V2,
    serverUrl: process.env.SERVER_URL,
  },
  hooks: {
    server: {
      devMiddleware() {
        //process.exit()
      },
    },
  },
  /*
   ** Headers of the page
   */
  head: {
    title: process.env.npm_package_name || '',
    meta: [
      {charset: 'utf-8'}
    ],
    script: [],
    link: [
      {rel: 'icon', type: 'image/x-icon', href: '/favicon.png'},
    ]
  },
  generate: {
    minify: {
      collapseWhitespace: false,
    },
  },
  /*
   ** Customize the progress-bar color
   */
  loading: {color: '#F8A12D'},
  /*
   ** Global CSS
   */
  css: ['normalize.css', {src: '~/assets/styles/common.less', lang: 'less'}],
  /*
   ** Plugins to load before mounting the App
   */
  plugins: ['~/plugins/v-tooltip', '~/plugins/currency-filter', '~/plugins/vue-phone-number-input', '~/plugins/recaptcha.client.js'],
  /*
   ** Nuxt.js dev-modules
   */
  buildModules: [],
  /*
   ** Nuxt.js modules
   */
  modules: [
    'vue-scrollto/nuxt',
    [
      'nuxt-i18n',
      {
        locales: ['ru', 'en'],
        defaultLocale: 'ru',
        vueI18n: {
          fallbackLocale: 'ru',
          messages: {ru, en},
        },
        detectBrowserLanguage: false,
        // detectBrowserLanguage: {
        //   alwaysRedirect: false,
        //   fallbackLocale: 'ru',
        //   onlyOnRoot: true,
        //   useCookie: true,
        //   cookieCrossOrigin: false,
        //   cookieDomain: null,
        //   cookieKey: 'i18n_redirected',
        //   cookieSecure: false,
        // },
      },
    ],
    '@nuxtjs/axios',
  ],
  /*
   ** Axios module configuration
   */
  axios: {},
  /*
   ** Build configuration
   */
  build: {
    publicPath: '/vueComponents/mainPage',
    transpile: [
      'vee-validate/dist/rules',
    ],
    extend(config, ctx) {
      //config.output.publicPath = 'http://localhost:8080/'
      if (ctx.isClient) {
        config.devtool = '#source-map'
      }

      config.node = {
        fs: 'empty',
      }
    },
  },
}
